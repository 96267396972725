import Head from 'next/head';

export default function FourZeroFourTemplate({ page, renderSections }) {
  const heading = page?.title;
  return (
    <>
      {heading && (
        <Head>
          <title>
            {heading && process.env.NEXT_PUBLIC_SITE_TITLE
              ? `${heading} | ${process.env.NEXT_PUBLIC_SITE_TITLE}`
              : (heading || process.env.NEXT_PUBLIC_SITE_TITLE || 'Rhoback')}
          </title>
        </Head>
      )}

      <div>{renderSections()}</div>
    </>
  );
}

FourZeroFourTemplate.displayName = 'FourZeroFourTemplate';
